import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/csr'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import CSRFlower from '../sections/csr/CSRFlower'

const Csr = () => {
  const { lang } = useLangContext()

  const { image, children } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/csr.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      children: allContentfulCsr(sort: { fields: subtitle }) {
        nodes {
          title
          slug
          node_locale
          subtitle
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
    }
  `)

  const links = children.nodes
    .filter((node) => node.node_locale === 'pl')
    .map((node) => ({
      slug: 'csr/' + node.slug,
      name: node.title,
      image: node.image,
      subtitle: node.subtitle,
    }))

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/csr.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} h={2} logo />
      <Main data={main} h={1} />
      <CSRFlower />
      <Tiles data={links} />
    </Layout>
  )
}

export default Csr
